import { useMatomo } from '@datapunt/matomo-tracker-react';
import {
  LdAccordion,
  LdAccordionPanel,
  LdAccordionSection,
  LdAccordionToggle,
  LdBadge,
  LdButton,
  LdIcon,
  LdInput,
  LdLabel,
  LdLoading,
  LdNotice,
  LdTab,
  LdTable,
  LdTableBody,
  LdTableCell,
  LdTableHead,
  LdTableHeader,
  LdTableRow,
  LdTablist,
  LdTabpanel,
  LdTabpanellist,
  LdTabs,
  LdToggle,
  LdTypo,
} from '@emdgroup-liquid/liquid/dist/react';
import { Badge, LightBox } from '@liquid-design/liquid-design-react';
import { nanoid } from 'nanoid';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import {
  ConstraintsSubTypeContinuous,
  ConstraintsSubTypeDiscrete,
  ContinuousConstraint,
  DiscreteConstraint,
  Parameter,
  ProjectRole,
  ProjectStatus,
  SubSelectionCondition,
  ThresholdCondition,
  isThresholdCondition,
} from 'types/api/types';
import {
  AcquisitionFunctionLabel,
  ConstraintDiscreteDependenciesType,
  ConstraintDiscretePermutationType,
  ConstraintSingleConditionType,
  InitialGuessMethodLabel,
  StateStatus,
  SurroGateModelLabel,
} from 'types/app/types';

import {
  AddParameterForm,
  SpaceDetails,
  SpaceLoader,
} from 'components/ChemicalSpace';
import ShareProject from 'components/ChemicalSpace/ShareProject/ShareProject';
import { TargetsList } from 'components/ChemicalSpace/TargetList/TargetsList';
import AppLayout from 'layouts/AppLayout';
import Disclaimer from 'layouts/Disclaimer';

import { calculateProgress, hasAtLeastRole } from 'util/project';
import { camelCaseToSpaces } from 'util/utils';

import useClientinfo from 'hooks/useClientinfo';
import useExpandSpace from 'hooks/useExpandSpace';
import { useHints } from 'hooks/useHints';

import Breadcrumbs from './components/Breadcrumbs';
import ExperimentsForm from './components/ExperimentsForm';
import { ParametersPanel } from './components/ParametersPanel';
import { ProjectHistory } from './components/ProjectHistory/ProjectHistory';
import { RecommendationsList } from './components/RecommendationsList';

import useProjectDetails from './hooks/useProjectDetails';
import useRecommendations from './hooks/useRecommendations';

import { useWatchChange } from 'hooks/useWatchChange';
import { getBaybeConfigs } from 'services/projects';
import { exportConfig } from 'util/csv';
import './styles.scss';
import { DEFAULT_DECIMALS } from 'config';

interface Props {}

const ProjectPage: React.FC<Props> = () => {
  const { projectId = '' } = useParams();
  const {
    project: selectedProject,
    status,
    close,
    reopen,
    refresh,
    isBusy,
    isLoading,
    experiments = [],
    recommendations = [],
    constraints,
    updateDisplayMode,
  } = useProjectDetails(projectId);
  const {
    startExpanding,
    cancelExpanding,
    finishExpanding,
    setNewParameter,
    setNewParameterValues,
    setNewParameterResults,
    newParameterValues,
    isExpanding: isEditing,
    hasEmptyParameterValues,
    newParameters,
  } = useExpandSpace(projectId);
  const { input, clearInputs } = useRecommendations(projectId);
  const navigate = useNavigate();
  const { trackPageView, trackEvent } = useMatomo();
  const { getToolTipTextById, getToolTipById, wrapLabel } = useHints();
  const { isChrome } = useClientinfo();
  const [modal, setModal] = useState<string>();
  const [selectedParameter, setSelectedParameter] = useState<Parameter>();
  const [displayMode, setDisplayMode] = useState<number | undefined>(
    selectedProject?.displayMode
  );
  const [isChecked, setChecked] = useState<boolean>(
    selectedProject?.displayMode !== undefined
  );

  useEffect(() => {
    setDisplayMode(selectedProject?.displayMode);
    setChecked(!!selectedProject?.displayMode);
  }, [selectedProject?.displayMode]);

  const remainingExperiments = useMemo(() => {
    if (!selectedProject) return 0;
    const { performed, total } = calculateProgress({
      project: selectedProject,
      experiments,
    });
    return total - performed;
  }, [selectedProject, experiments]);

  useEffect(() => {
    return () => {
      clearInputs();
    };
  }, []);

  React.useEffect(() => {
    trackPageView({
      documentTitle: 'Project Details',
    });
  }, []);

  const isClosed = useMemo(
    () => selectedProject?.status === ProjectStatus.closed,
    [selectedProject]
  );
  const tabRef = useRef<HTMLLdTabsElement | null>(null);

  useWatchChange(status === 'success', false, true, () => {
    let index = 1;
    if (isClosed || remainingExperiments <= 0) {
      tabRef.current?.switchTab(0);
      return;
    }
    if (recommendations.length > 0) {
      index = 2;
    } else if (experiments.length > 0) {
      index = 0;
    }
    tabRef.current?.switchTab(index);
  });

  useWatchChange(recommendations.length, 1, 0, () => {
    setTimeout(() => tabRef.current?.switchTab(1), 0);
  });

  const inputsArray = useMemo(
    () => Object.keys(input).map((key) => ({ key, ...input[key] })),
    [input, projectId]
  );

  const targetListValues = useMemo(() => {
    if (!selectedProject?.targets) return {};
    return selectedProject?.targets.reduce((prev, curr) => {
      return Object.assign(prev, {
        [nanoid()]: curr,
      });
    }, {});
  }, [selectedProject?.targets]);

  const openExpandModal = (parameter?: Parameter) => {
    setSelectedParameter(parameter);
    setModal('expandSpace');

    if (parameter && newParameterValues(parameter.parameterName).length === 0) {
      setNewParameterValues({
        parameterName: parameter.parameterName,
        values: parameter.parameterValues,
      });
    }
  };

  const handleExpandParameter = (parameter: Parameter) => {
    const { parameterValues } = parameter;
    if (projectId !== undefined && selectedParameter !== undefined) {
      trackEvent({
        category: 'project',
        action: 'extend-parameters:new-parameter',
      });
      setNewParameterValues({
        parameterName: selectedParameter.parameterName,
        values: parameterValues,
      });
    } else if (projectId !== undefined) {
      setNewParameter(parameter);
      trackEvent({
        category: 'project',
        action: 'extend-parameters:new-value',
      });
    }
    setModal(undefined);
    setSelectedParameter(undefined);
  };

  const handleCloseProject = async () => {
    setModal(undefined);
    await close();
    navigate('/projects');
  };

  const handleReopenProject = async () => {
    setModal(undefined);
    await reopen();
    refresh();
  };

  const getExpertLabel = (key: string) => {
    return (
      InitialGuessMethodLabel.get(key) ||
      AcquisitionFunctionLabel.get(key) ||
      SurroGateModelLabel.get(key) ||
      key
    );
  };

  if (!projectId) return null;

  const getBaybeConfig = async (projectId: string) => {
    const data = await getBaybeConfigs(projectId);
    exportConfig(data, projectId);
  };

  const renderActionButtons = () => {
    if (isClosed || !hasAtLeastRole(ProjectRole.Editor, selectedProject))
      return null;
    if (isEditing) {
      return (
        <>
          <LdButton onClick={cancelExpanding} mode="danger-secondary">
            Cancel
          </LdButton>
          <LdButton
            data-tip={'Please provide missing values for the history'}
            data-for="save-tooltip"
            data-tip-disable={!hasEmptyParameterValues}
            onClick={finishExpanding}
            disabled={hasEmptyParameterValues}
            mode="secondary"
          >
            Save
          </LdButton>
          <ReactTooltip
            id="save-tooltip"
            type="light"
            effect="solid"
            className="tooltip"
          />
        </>
      );
    }
    return (
      <>
        <LdButton
          onClick={startExpanding}
          mode="secondary"
          data-tip={getToolTipTextById('Exp_3')}
          disabled={isBusy || isLoading}
          data-for="export-tooltip"
        >
          Extend Parameters
        </LdButton>
        {hasAtLeastRole(ProjectRole.Owner, selectedProject) && (
          <>
            <LdButton onClick={() => setModal('closeProject')} mode="danger">
              Close Project
            </LdButton>
          </>
        )}
      </>
    );
  };

  const reopenProjectButton = isClosed &&
    hasAtLeastRole(ProjectRole.Owner, selectedProject) && (
      <LdButton onClick={handleReopenProject}>Reopen Project</LdButton>
    );

  return (
    <AppLayout>
      <div id="project" className="relative flex max-w-[1024px] mx-auto">
        <div className="relative flex flex-col items-start justify-center w-full max-h-screen min-h-screen pt-8">
          {!isChrome && <Disclaimer />}
          <div className="pl-4">
            <Breadcrumbs
              projectId={projectId}
              projectName={selectedProject?.name}
            />
          </div>
          <div
            className={`flex flex-col items-center justify-start flex-grow w-full px-4 pb-6 gap-8 ${
              selectedProject?.status != ProjectStatus.creating
                ? 'overflow-y-auto'
                : 'overflow-hidden'
            }`}
          >
            {status === StateStatus.loading &&
              selectedProject === undefined && <SpaceLoader />}
            {selectedProject && (
              <SpaceDetails
                {...selectedProject}
                editing={isEditing}
                onEdit={openExpandModal}
                experiments={experiments}
              >
                {selectedProject.expertOptions !== undefined && (
                  <div>
                    <LdAccordion tone="dark" rounded>
                      <LdAccordionSection>
                        <LdAccordionToggle>
                          <LdTypo variant="label-s">Targets</LdTypo>
                        </LdAccordionToggle>
                        <LdAccordionPanel>
                          <div className="p-2">
                            <TargetsList targets={targetListValues} readOnly />
                          </div>
                        </LdAccordionPanel>
                      </LdAccordionSection>
                      <LdAccordionSection expanded={isEditing}>
                        <LdAccordionToggle>
                          <LdTypo variant="label-s">
                            Parameters {getToolTipById('Spc_1')}
                          </LdTypo>
                        </LdAccordionToggle>
                        <LdAccordionPanel>
                          <ParametersPanel
                            selectedProject={selectedProject}
                            isEditing={isEditing}
                            openExpandModal={openExpandModal}
                            newParameterValues={newParameterValues}
                            getToolTipTextById={getToolTipTextById}
                            newParameters={newParameters}
                          />
                        </LdAccordionPanel>
                      </LdAccordionSection>
                      {constraints?.length > 0 && (
                        <LdAccordionSection>
                          <LdAccordionToggle>
                            <LdTypo variant="label-s">Constraints</LdTypo>
                          </LdAccordionToggle>
                          <LdAccordionPanel>
                            <div className="p-ld-24 gap-y-2 flex flex-col">
                              {constraints.map((constr, index) => (
                                <div
                                  key={index + 'constra'}
                                  className="flex flex-col"
                                >
                                  <span className="flex flex-row justify-between">
                                    <LdTypo variant="label-s" className="mb-3">
                                      {constr.type &&
                                        constr.type
                                          .split(/(?=[A-Z])/)
                                          .join(' ')}
                                    </LdTypo>
                                  </span>
                                  {(constr.type as string) ==
                                    ConstraintsSubTypeContinuous.ContinuousLinearConstraint && (
                                    <>
                                      <div className="flex flex-row flex-grow justify-start">
                                        <LdTypo
                                          variant="label-s"
                                          className="mb-5 mr-5"
                                        >
                                          Operator:{' '}
                                          <LdBadge className="ld-badge--info">
                                            {
                                              (constr as ContinuousConstraint)
                                                .operator
                                            }
                                          </LdBadge>
                                        </LdTypo>
                                        <LdTypo
                                          variant="label-s"
                                          className="mb-5"
                                        >
                                          Righ-Hand Side:{' '}
                                          {(constr as ContinuousConstraint).rhs}
                                        </LdTypo>
                                      </div>
                                      <LdTable>
                                        <LdTableHead>
                                          <LdTableRow>
                                            <LdTableHeader>
                                              Parameter
                                            </LdTableHeader>
                                            <LdTableHeader>
                                              Coefficient
                                            </LdTableHeader>
                                          </LdTableRow>
                                        </LdTableHead>
                                        <LdTableBody>
                                          {constr.parameters.map(
                                            (param, index) => (
                                              <LdTableRow
                                                key={`${param}-${index}`}
                                              >
                                                <LdTableCell>
                                                  <LdTypo
                                                    variant="body-s"
                                                    className="max-w-[10rem]"
                                                    style={{
                                                      'overflow-wrap':
                                                        'anywhere',
                                                    }}
                                                  >
                                                    {param}
                                                  </LdTypo>
                                                </LdTableCell>
                                                <LdTableCell>
                                                  <LdTypo
                                                    variant="body-s"
                                                    className="max-w-[10rem]"
                                                    style={{
                                                      'overflow-wrap':
                                                        'anywhere',
                                                    }}
                                                  >
                                                    {
                                                      (
                                                        constr as ContinuousConstraint
                                                      ).coefficients[index]
                                                    }
                                                  </LdTypo>
                                                </LdTableCell>
                                              </LdTableRow>
                                            )
                                          )}
                                        </LdTableBody>
                                      </LdTable>
                                    </>
                                  )}
                                  {((constr.type as string) ==
                                    ConstraintsSubTypeDiscrete.DiscreteNoLabelDuplicatesConstraint ||
                                    (constr.type as string) ==
                                      ConstraintsSubTypeDiscrete.DiscreteLinkedParametersConstraint) && (
                                    <>
                                      <div className="flex flex-col flex-grow justify-start">
                                        {constr.parameters.map((p) => (
                                          <LdTypo
                                            key={p}
                                            variant="cap-m"
                                            className="mb-2"
                                          >
                                            {p}
                                          </LdTypo>
                                        ))}
                                      </div>
                                    </>
                                  )}
                                  {((constr.type as string) ==
                                    ConstraintsSubTypeDiscrete.DiscreteSumConstraint ||
                                    (constr.type as string) ==
                                      ConstraintsSubTypeDiscrete.DiscreteProductConstraint) && (
                                    <>
                                      <div className="flex flex-col flex-grow justify-start mb-2 border-b-2">
                                        {constr.parameters.map((p) => (
                                          <LdTypo
                                            key={p}
                                            variant="cap-m"
                                            className="mb-2"
                                          >
                                            {p}
                                          </LdTypo>
                                        ))}
                                      </div>
                                      Threshold Condition
                                      <div className="flex flex-row justify-between p-2 text-thm-primary">
                                        <LdTypo
                                          variant="body-m"
                                          className="max-w-[10rem]"
                                          style={{
                                            'overflow-wrap': 'anywhere',
                                          }}
                                        >
                                          {'Operator : '}
                                          {
                                            (
                                              constr as ConstraintSingleConditionType
                                            )?.condition?.operator
                                          }
                                        </LdTypo>
                                        <LdTypo
                                          variant="body-m"
                                          className="max-w-[10rem]"
                                          style={{
                                            'overflow-wrap': 'anywhere',
                                          }}
                                        >
                                          {'Threshold : '}
                                          {
                                            (
                                              constr as ConstraintSingleConditionType
                                            )?.condition?.threshold
                                          }
                                        </LdTypo>
                                        <LdTypo
                                          variant="body-m"
                                          className="max-w-[10rem]"
                                          style={{
                                            'overflow-wrap': 'anywhere',
                                          }}
                                        >
                                          {'Tolerance : '}
                                          {(
                                            constr as ConstraintSingleConditionType
                                          )?.condition?.tolerance || '-'}
                                        </LdTypo>
                                      </div>
                                    </>
                                  )}

                                  {constr &&
                                    (constr.type as string) ==
                                      ConstraintsSubTypeDiscrete.DiscretePermutationInvarianceConstraint && (
                                      <>
                                        <LdLabel className="mb-2">
                                          Paramters
                                          <LdTypo
                                            variant="body-s"
                                            className="max-w-[10rem] mr-2"
                                            style={{
                                              'overflow-wrap': 'anywhere',
                                            }}
                                          >
                                            {constr.parameters.join(', ')}
                                          </LdTypo>
                                        </LdLabel>

                                        <LdTable>
                                          <LdTableHead>
                                            <LdTableRow>
                                              <LdTableHeader>
                                                Dependencies Params
                                              </LdTableHeader>
                                              <LdTableHeader>
                                                Conditions
                                              </LdTableHeader>
                                              <LdTableHeader>
                                                Affected Params
                                              </LdTableHeader>
                                            </LdTableRow>
                                          </LdTableHead>
                                          <LdTableBody>
                                            {(
                                              constr as ConstraintDiscretePermutationType
                                            ).dependencies?.parameters?.map(
                                              (param, index) => (
                                                <LdTableRow
                                                  key={`${param}-${index}`}
                                                >
                                                  <LdTableCell>
                                                    <LdTypo
                                                      variant="body-s"
                                                      className="max-w-[10rem]"
                                                      style={{
                                                        'overflow-wrap':
                                                          'anywhere',
                                                      }}
                                                    >
                                                      {param}
                                                    </LdTypo>
                                                  </LdTableCell>

                                                  <LdTableCell>
                                                    {isThresholdCondition(
                                                      (
                                                        constr as ConstraintDiscretePermutationType
                                                      )?.dependencies
                                                        ?.conditions?.[index]
                                                    ) ? (
                                                      <LdLabel size="m">
                                                        Threshold Condition
                                                        <div className="flex flex-row border rounded-m  border-collapse justify-between p-2 text-thm-primary mx-auto  border-rich-purple-dark">
                                                          <LdTypo
                                                            variant="body-m"
                                                            className="max-w-[10rem] mr-2"
                                                            style={{
                                                              'overflow-wrap':
                                                                'anywhere',
                                                            }}
                                                          >
                                                            {'Operator : '}
                                                            <LdBadge className="ld-badge--info">
                                                              {
                                                                (
                                                                  (
                                                                    constr as ConstraintDiscretePermutationType
                                                                  )
                                                                    ?.dependencies
                                                                    ?.conditions?.[
                                                                    index
                                                                  ] as ThresholdCondition
                                                                ).operator
                                                              }
                                                            </LdBadge>
                                                          </LdTypo>
                                                          <LdTypo
                                                            variant="body-m"
                                                            className="max-w-[10rem mx-2"
                                                            style={{
                                                              'overflow-wrap':
                                                                'anywhere',
                                                            }}
                                                          >
                                                            {'Threshold : '}
                                                            <b>
                                                              {
                                                                (
                                                                  (
                                                                    constr as ConstraintDiscretePermutationType
                                                                  )
                                                                    ?.dependencies
                                                                    ?.conditions?.[
                                                                    index
                                                                  ] as ThresholdCondition
                                                                ).threshold
                                                              }
                                                            </b>
                                                          </LdTypo>
                                                          <LdTypo
                                                            variant="body-m"
                                                            className="max-w-[10rem]"
                                                            style={{
                                                              'overflow-wrap':
                                                                'anywhere',
                                                            }}
                                                          >
                                                            {'Tolerance : '}
                                                            <b>
                                                              {(
                                                                (
                                                                  constr as ConstraintDiscretePermutationType
                                                                )?.dependencies
                                                                  ?.conditions?.[
                                                                  index
                                                                ] as ThresholdCondition
                                                              ).tolerance ||
                                                                ' -- '}
                                                            </b>
                                                          </LdTypo>
                                                        </div>
                                                      </LdLabel>
                                                    ) : (
                                                      <LdLabel size="m">
                                                        Sub Selection Condition
                                                        <div className="flex flex-row w-full border rounded-m  border-collapse p-2 text-thm-primary mx-auto  border-rich-purple-dark">
                                                          {(
                                                            (
                                                              constr as ConstraintDiscretePermutationType
                                                            )?.dependencies
                                                              ?.conditions?.[
                                                              index
                                                            ] as SubSelectionCondition
                                                          )?.selection?.map(
                                                            (e) => (
                                                              <div
                                                                className="mr-2 box-border rounded-m"
                                                                key={e}
                                                              >
                                                                {e}
                                                              </div>
                                                            )
                                                          )}
                                                        </div>
                                                      </LdLabel>
                                                    )}
                                                  </LdTableCell>
                                                  {
                                                    <LdTableCell>
                                                      <LdTypo
                                                        variant="body-s"
                                                        className="max-w-[10rem]"
                                                        style={{
                                                          'overflow-wrap':
                                                            'anywhere',
                                                        }}
                                                      >
                                                        {(
                                                          constr as ConstraintDiscretePermutationType
                                                        ).dependencies.affected_parameters?.join(
                                                          ', '
                                                        )}
                                                      </LdTypo>
                                                    </LdTableCell>
                                                  }
                                                </LdTableRow>
                                              )
                                            )}
                                          </LdTableBody>
                                        </LdTable>
                                      </>
                                    )}

                                  {(constr.type as string) ==
                                    ConstraintsSubTypeDiscrete.DiscreteExcludeConstraint ||
                                    ((constr.type as string) ==
                                      ConstraintsSubTypeDiscrete.DiscreteDependenciesConstraint && (
                                      <>
                                        {(constr.type as string) ==
                                          ConstraintsSubTypeDiscrete.DiscreteExcludeConstraint && (
                                          <div className="flex flex-row flex-grow justify-start">
                                            <LdTypo
                                              variant="label-s"
                                              className="mb-5 mr-5"
                                            >
                                              Combiner:{' '}
                                              <LdBadge className="ld-badge--info">
                                                {
                                                  (constr as DiscreteConstraint)
                                                    .combiner
                                                }
                                              </LdBadge>
                                            </LdTypo>
                                          </div>
                                        )}
                                        <LdTable>
                                          <LdTableHead>
                                            <LdTableRow>
                                              <LdTableHeader>
                                                Parameter
                                              </LdTableHeader>
                                              <LdTableHeader>
                                                Conditions
                                              </LdTableHeader>
                                              {(constr.type as string) ==
                                                ConstraintsSubTypeDiscrete.DiscreteDependenciesConstraint && (
                                                <LdTableHeader>
                                                  Affected Paramters
                                                </LdTableHeader>
                                              )}
                                            </LdTableRow>
                                          </LdTableHead>
                                          <LdTableBody>
                                            {constr.parameters.map(
                                              (param, index) => (
                                                <LdTableRow
                                                  key={`${param}-${index}`}
                                                >
                                                  <LdTableCell>
                                                    <LdTypo
                                                      variant="body-s"
                                                      className="max-w-[10rem]"
                                                      style={{
                                                        'overflow-wrap':
                                                          'anywhere',
                                                      }}
                                                    >
                                                      {param}
                                                    </LdTypo>
                                                  </LdTableCell>
                                                  <LdTableCell>
                                                    {isThresholdCondition(
                                                      (
                                                        constr as DiscreteConstraint
                                                      )?.conditions?.[index]
                                                    ) ? (
                                                      <LdLabel size="m">
                                                        Threshold Condition
                                                        <div className="flex flex-row border rounded-m  border-collapse justify-between p-2 text-thm-primary mx-auto  border-rich-purple-dark">
                                                          <LdTypo
                                                            variant="body-m"
                                                            className="max-w-[10rem] mr-2"
                                                            style={{
                                                              'overflow-wrap':
                                                                'anywhere',
                                                            }}
                                                          >
                                                            {'Operator : '}
                                                            <LdBadge className="ld-badge--info">
                                                              {
                                                                (
                                                                  (
                                                                    constr as DiscreteConstraint
                                                                  )
                                                                    ?.conditions?.[
                                                                    index
                                                                  ] as ThresholdCondition
                                                                ).operator
                                                              }
                                                            </LdBadge>
                                                          </LdTypo>
                                                          <LdTypo
                                                            variant="body-m"
                                                            className="max-w-[10rem mx-2"
                                                            style={{
                                                              'overflow-wrap':
                                                                'anywhere',
                                                            }}
                                                          >
                                                            {'Threshold : '}
                                                            <b>
                                                              {
                                                                (
                                                                  (
                                                                    constr as DiscreteConstraint
                                                                  )
                                                                    ?.conditions?.[
                                                                    index
                                                                  ] as ThresholdCondition
                                                                ).threshold
                                                              }
                                                            </b>
                                                          </LdTypo>
                                                          <LdTypo
                                                            variant="body-m"
                                                            className="max-w-[10rem]"
                                                            style={{
                                                              'overflow-wrap':
                                                                'anywhere',
                                                            }}
                                                          >
                                                            {'Tolerance : '}
                                                            <b>
                                                              {(
                                                                (
                                                                  constr as DiscreteConstraint
                                                                )?.conditions?.[
                                                                  index
                                                                ] as ThresholdCondition
                                                              ).tolerance ||
                                                                ' -- '}
                                                            </b>
                                                          </LdTypo>
                                                        </div>
                                                      </LdLabel>
                                                    ) : (
                                                      <LdLabel size="m">
                                                        Sub Selection Condition
                                                        <div className="flex flex-row w-full border rounded-m  border-collapse p-2 text-thm-primary mx-auto  border-rich-purple-dark">
                                                          {(
                                                            (
                                                              constr as DiscreteConstraint
                                                            )?.conditions?.[
                                                              index
                                                            ] as SubSelectionCondition
                                                          )?.selection?.map(
                                                            (e) => (
                                                              <div
                                                                className="mr-2 box-border rounded-m"
                                                                key={e}
                                                              >
                                                                {e}
                                                              </div>
                                                            )
                                                          )}
                                                        </div>
                                                      </LdLabel>
                                                    )}
                                                  </LdTableCell>
                                                  {(constr.type as string) ==
                                                    ConstraintsSubTypeDiscrete.DiscreteDependenciesConstraint && (
                                                    <LdTableCell>
                                                      <LdTypo
                                                        variant="body-s"
                                                        className="max-w-[10rem]"
                                                        style={{
                                                          'overflow-wrap':
                                                            'anywhere',
                                                        }}
                                                      >
                                                        {(
                                                          constr as ConstraintDiscreteDependenciesType
                                                        ).affected_parameters?.[
                                                          index
                                                        ]?.join(', ')}
                                                      </LdTypo>
                                                    </LdTableCell>
                                                  )}
                                                </LdTableRow>
                                              )
                                            )}
                                          </LdTableBody>
                                        </LdTable>
                                      </>
                                    ))}
                                </div>
                              ))}
                            </div>
                          </LdAccordionPanel>
                        </LdAccordionSection>
                      )}
                      <LdAccordionSection>
                        <LdAccordionToggle>
                          <LdTypo variant="label-s">Advanced Settings</LdTypo>
                        </LdAccordionToggle>
                        <LdAccordionPanel>
                          <div className="p-ld-24 gap-y-2 flex flex-col">
                            {Object.keys(selectedProject.expertOptions).map(
                              (key) => (
                                <LdTypo key={key} variant="label-s">
                                  {camelCaseToSpaces(key)}:{' '}
                                  <span className="font-normal">
                                    {getExpertLabel(
                                      (selectedProject.expertOptions as any)[
                                        key
                                      ]
                                    )}
                                  </span>
                                </LdTypo>
                              )
                            )}
                          </div>
                        </LdAccordionPanel>
                      </LdAccordionSection>
                    </LdAccordion>
                  </div>
                )}
                <div className="flex justify-end gap-4">
                  {hasAtLeastRole(ProjectRole.Owner, selectedProject) && (
                    <ShareProject projectId={projectId} />
                  )}
                  {/* TO DO: uncomment once feature should be release */}
                  <LdButton
                    onClick={() =>
                      navigate(`/new-project/?cloneProject=${projectId}`)
                    }
                    size="sm"
                    mode="secondary"
                  >
                    <LdIcon name="copy" aria-label="clone project" />
                    Clone
                  </LdButton>
                  <LdButton
                    size="sm"
                    onClick={() => getBaybeConfig(projectId)}
                    mode="secondary"
                  >
                    <LdIcon
                      name="cloud-download"
                      aria-label="downlaod config"
                    />
                    BayBE Config
                  </LdButton>
                  <div className="flex-grow" />
                  {renderActionButtons()}
                  {reopenProjectButton}
                </div>
              </SpaceDetails>
            )}
            {!!selectedProject?.error && (
              <LdNotice mode="error" headline="Error" className="w-full">
                {selectedProject.error}
              </LdNotice>
            )}

            <LdTabs className="w-full" ref={tabRef}>
              <ReactTooltip
                id="tabs-tooltip"
                type="light"
                effect="solid"
                className="tooltip"
                multiline={true}
              />
              <LdTablist mode="ghost">
                <LdTab
                  data-tip={getToolTipTextById('Exp_4')}
                  data-for="tabs-tooltip"
                  disabled={!experiments.length}
                  data-id="tab-history"
                >
                  History {hasEmptyParameterValues && <Badge>!</Badge>}
                </LdTab>
                <LdTab
                  disabled={
                    isClosed ||
                    !hasAtLeastRole(ProjectRole.Editor, selectedProject) ||
                    remainingExperiments <= 0
                  }
                  data-tip={
                    remainingExperiments <= 0
                      ? 'Number of possible experiments exhausted'
                      : getToolTipTextById('Exp_5')
                  }
                  data-for="tabs-tooltip"
                  data-id="tab-input"
                >
                  Input{' '}
                  {inputsArray.length > 0 && (
                    <Badge>{inputsArray.length}</Badge>
                  )}
                </LdTab>
                <LdTab
                  disabled={isClosed || remainingExperiments <= 0}
                  data-tip={
                    remainingExperiments <= 0
                      ? 'Number of possible experiments exhausted'
                      : getToolTipTextById('Exp_6')
                  }
                  data-for="tabs-tooltip"
                  data-id="tab-recommendations"
                >
                  Recommendations{' '}
                  {selectedProject?.status === ProjectStatus.running && (
                    <LdLoading neutral className="ld-loading-sm" />
                  )}
                </LdTab>
                <div className="absolute right-0">
                  <span className="flex flex-row items-center">
                    <LdLabel>{wrapLabel('ROUND_1', 'Rounding')}</LdLabel>
                    <LdToggle
                      size="sm"
                      className="mx-1"
                      checked={isChecked}
                      onLdchange={(event) => {
                        const val: boolean = event.detail;
                        setChecked(val);
                        if (!val) {
                          updateDisplayMode(undefined);
                          setDisplayMode(undefined);
                        } else {
                          updateDisplayMode(DEFAULT_DECIMALS);
                          setDisplayMode(DEFAULT_DECIMALS);
                        }
                      }}
                    />
                    <LdInput
                      disabled={!isChecked}
                      style={{ maxWidth: '2rem' }}
                      className="w-20 ml-2"
                      type="number"
                      size="sm"
                      placeholder="3"
                      required
                      min={1}
                      step="1"
                      onLdchange={(ev) => {
                        const v = ev.target.value;
                        v
                          ? updateDisplayMode(Number(v))
                          : setDisplayMode(undefined);
                      }}
                      value={displayMode?.toString()}
                    />
                  </span>
                </div>
              </LdTablist>
              <LdTabpanellist>
                <LdTabpanel className="gap-8">
                  {selectedProject && (
                    <ProjectHistory
                      displayMode={selectedProject?.displayMode}
                      selectedProject={selectedProject}
                      onChange={(res) => {
                        setNewParameterResults({
                          experimentId: res.experimentId,
                          parameters: { [res.parameterName]: res.value },
                        });
                      }}
                    />
                  )}
                </LdTabpanel>
                <LdTabpanel className="gap-8">
                  <ExperimentsForm
                    projectId={projectId}
                    remainingExperiments={remainingExperiments}
                    onSuccess={() => tabRef.current?.switchTab(0)}
                  />
                </LdTabpanel>
                <LdTabpanel>
                  {selectedProject && (
                    <RecommendationsList
                      displayMode={selectedProject?.displayMode}
                      selectedProject={selectedProject}
                      isBusy={status === StateStatus.loading}
                      remainingExperiments={remainingExperiments}
                      disabled={
                        !hasAtLeastRole(ProjectRole.Editor, selectedProject)
                      }
                    />
                  )}
                </LdTabpanel>
              </LdTabpanellist>
            </LdTabs>
          </div>
        </div>
      </div>

      <LightBox
        label="Add Parameter"
        open={modal === 'expandSpace'}
        onClose={() => {
          setModal(undefined);
        }}
      >
        <div className="relative flex w-full max-h-[60rem] flex-grow min-h-[18rem]">
          <AddParameterForm
            isExpanded={
              selectedProject?.status == 'idle' &&
              isEditing &&
              !selectedParameter
            }
            projectStatus={selectedProject?.status}
            onCancel={() => setModal(undefined)}
            data-test="upload-card-drop"
            parameter={selectedParameter}
            readOnlyParameter={selectedProject?.parameterSpace.find(
              (parameter) =>
                parameter.parameterType === selectedParameter?.parameterType &&
                parameter.parameterName === selectedParameter?.parameterName
            )}
            values={
              selectedParameter
                ? newParameterValues(selectedParameter.parameterName)
                : undefined
            }
            onSubmit={(param) => {
              handleExpandParameter(param);
              tabRef.current?.switchTab(0);
            }}
          />
        </div>
      </LightBox>
      <LightBox
        label={`Close Project "${selectedProject?.name}"`}
        open={modal === 'closeProject'}
        onClose={() => setModal(undefined)}
      >
        <div className="relative flex w-full flex-col gap-4">
          <LdTypo>
            Do you really want to close and archive this Projects?
          </LdTypo>
          <div className="flex flex-row items-end justify-end gap-x-ld-8">
            <LdButton
              onClick={() => setModal(undefined)}
              mode="danger-secondary"
            >
              Cancel
            </LdButton>
            <LdButton onClick={handleCloseProject} mode="danger">
              Close Project
            </LdButton>
          </div>
        </div>
      </LightBox>
    </AppLayout>
  );
};

export default ProjectPage;
